import Header from '../../Header.vue'
import Footer from '../../Footer.vue'

export default{
	name:'SeniorSearch',
	data(){
		return{
			dateTime:'',
			resType:[], //资源类型
			classOptions:[
				{value: '0',label: '标题'},
				{value: '1',label: '全文'},
				{value: '2',label: '关键词'}
			], //信息
			operatorOptions:[{value: '0',label: '精确'},{value: '1',label: '模糊'}], 
			searchWords:[{type:'0',word:'',operator:'0'}],
		}
	},
	methods:{
		addSearch(){ //添加搜索条件
			var that = this
			if (that.searchWords.length < 2) {
				let item = {}
				item.type = '0'
				item.word = ''
				item.operator = '0'
				that.searchWords.push(item)
			}
		},
		deleteSearch(){ //减少搜索条件
			var that = this
			if (that.searchWords.length > 1) {
				for (let i = that.searchWords.length-1; i >=1; i--) {
					that.searchWords.splice(i,1)
					return
				}
			}
		},
		toSearchResult(){ //搜索
			var that = this
			var ishave = false
			that.searchWords.forEach(item=>{
				if(item.word == ''){
					this.$alert('请输入搜索内容', '提示', {
						confirmButtonText: '确定'
					});
					ishave = true
					return
				}
			})
			if(!ishave){
				var search = {}
				search.searchCon = that.searchWords
				search.resType = that.resType
				search.startDate = that.dateTime?that.dateTime[0]:''
				search.endDate = that.dateTime?that.dateTime[1]:'',
				window.open(that.$WebUrl + 'SearchResult?searchType=1' + '&searchCon='+JSON.stringify(search))
			}
		}
	},
	components:{
		'Header': Header,
		'Footer':Footer
	}
}